<!-- 打卡详情 -->
<template lang="">
<div style="height:100%;padding:0 20px 10px;box-sizing:border-box">
    <div class="handleBox flex flex-ai-c">
        <el-page-header @back="goBack" content=""></el-page-header>
        <div class="" style="color:#333">
            <span style="marginRight:30px">业务员：{{$route.query.name}}</span>
        </div>
    </div>
    <!-- 表格 -->
    <div class="table" style="height:calc( (100% - 175px) * 0.6 );paddingTop:10px">
        <div class="flex flex-ai-c flex-wrap" style="height:40px;color:#666">
            <span>{{$route.query.month}}月</span>
            <div class="" style="marginLeft:30px;fontSize:14px">
                打卡次数：{{list.length||0}}
            </div>
        </div>
        <el-table ref="table" :data="list" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="admin.name" label="业务员" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="user.name" label="拜访客户名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="create_time" label="拜访时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="mobile" label="拍照" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                    <el-image style="width: 30px; height: 30px" :src="getImgUrl(scope.row.img[0].path)" :preview-src-list="getImgUrl(scope.row.img,'arr')" fit="cover">
                        <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline" style="font-size:30px"></i>
                        </div>
                    </el-image>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="位置" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="note" label="跟进" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
    <div class="table" style="height:calc( (100% - 175px) * 0.4 );paddingTop:10px">
        <div class="flex flex-ai-c flex-wrap" style="height:40px">
            <div class="" style="fontSize:16px">
                服务评价
            </div>
        </div>
        <el-table ref="table" :data="listP" highlight-current-row :cell-style="{color:'#515a6e',borderColor:'#E8EAEC'}" :header-cell-style="{background:'#F8F8F9',color:'#515A6E'}" @sort-change='getList()' height="calc( 100% - 41px )">
            <el-table-column prop="create_time" label="评价时间" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="user.name" label="客户名称" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="help_zheng" label="业务员有帮你整理货架吗？" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="tell_goods" label="业务员有给您介绍新产品吗？" align="center" show-overflow-tooltip></el-table-column>
            <el-table-column prop="no_right" label="你对客服有什么不满意？" align="center" show-overflow-tooltip></el-table-column>
        </el-table>
    </div>
</div>
</template>

<script>
export default {
    data: function() {
        return {
            list: [],
            listP: []
        }
    },
    mounted: function() {
        this.getList();
        this.getPj()
    },
    methods: {
        //返回上一页
        goBack() {
            this.$router.go(-1)
        },

        async addressHandleChange() {
            this.curr = 1;
            await this.getList();
        },
        getList: function() {
            this.http.post('/admin.AdminDay/indexByAdmin', {
                admin_id: this.$route.query.id,
                month: this.$route.query.month
            }).then(re => {
                this.list = re.data;
            })
        },
        getPj() {
            this.http.post('/admin.AdminPing/index', {
                admin_id: this.$route.query.id,
                month: this.$route.query.month
            }).then(re => {
                this.listP = re.data;
            })
        },

        // 图片转换
        getImgUrl(item, type) {
            var url
            if(type == 'arr') {
                url = item.map(i => {
                    return this.domain + i.path
                })
            } else {
                url = this.domain + item;
            }
            return url;
        },

        //翻页
        currentChange: function(curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function(row) {
            this.row = row;
            this.getList();
        },
    }
}
</script>
